.paddingTwelvepx {
  padding: 12px;
}

.bookingContainer {
  /* width: 40%; */
  display: flex;
  justify-content: space-between;
}

.marginZero {
  margin: 0px;
}

.fontBold {
  font-weight: 600;
}

.successbgColor {
  background-color: rgba(9, 154, 6, 1);
}

.whiteColor {
  color: white;
}

.idprop {
  font-size: 22px;
}

.topContainerRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  width: 28%;
  color: black;
  /* font-size: 20px; */
  font-weight: 400;
}

.confirmedbtn {
  color: white;
  padding: 5px 14px;
  border-radius: 23px;
}

.confirmedbtnand {
  color: white;
  padding: 5px 45px;
  border-radius: 23px;
}

.primaryOrangeColor {
  color: rgba(233, 94, 51, 1);
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexContainerleft {
  width: 75%;
}

.flexContainerright {
  width: 20%;
  border-radius: 10px;
}

.widthhundrede {
  width: 100%;
}

.colorGray {
  color: rgba(0, 0, 0, 0.5);
}

.droneAndAccCard {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 10px;
  background: rgba(253, 147, 11, 0.2);
  padding: 10px;
  width: max-content;
}

.droneAndAccCardCOn {
  display: flex;
  gap: 20px;
  margin-top: 12px;
}

.marginTwelve {
  margin: 12px;
}

.marginToptwelve {
  margin-top: 12px;
}

.widthContainer {
  display: flex;
  justify-content: space-between;
}

.leftWrapperbooking {
  width: 55%;
}

.rightWrapperbooking {
  width: 35%;
}

.progress {
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background: #fff;
  margin-top: 46px;
  box-sizing: border-box;
  padding: 12px;
}

.studentDocument {
  font-size: 22px;
}

.minwidthtw {
  width: 200px;
}

.infoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.marginfive {
  margin-top: 5px 0px;
}

.colorlightgray {
  color: rgba(114, 114, 114, 1);
}

.downloadInvoice {
  border-radius: 5px;
  background: var(
    --Primary-gradient,
    linear-gradient(90deg, #ff9908 0%, #ed671f 100.02%)
  );
  color: white;
  padding: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
}

.paymentDetailsMainCon {
  width: 100%;
  margin-top: 30px;
}
.paymentTitle {
  padding: 0px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.paymentDetailsCon {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.topContainerRight span {
  color: black;
  /* background-color: rgba(235, 250, 255, 1); */
  padding: 10px 20px;
  box-sizing: border-box;
  color: rgba(27, 193, 255, 1);
  font-weight: 600;
  border-radius: 10px;
}

.transactionListCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
}
.transactionCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  color: white;
  margin-bottom: 10px;
}
.transactionCon h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.transactionCon p {
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0px;
}
.transactionCon h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

.withdrawListCon {
  margin-top: 20px;
}
.withdrawCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.withdrawBlack {
  color: black;
  /* font-size: 24px; */
  font-weight: 400;
}
.withdrawGrey {
  color: gray;
  /* font-size: 24px; */
  font-weight: 400;
  margin: 0px;
}
.withdrawRed {
  background-color: rgba(255, 227, 219, 1);
  color: rgba(237, 103, 31, 1);
  font-size: 12px;
  /* font-weight: 500; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 20px;
}

.downloadBtn {
  border: none;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.formContainer {
  padding-left: 10px;
  padding-right: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}