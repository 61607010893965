.mainContainer {
  border-radius: 10px;
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topContainer button {
  color: white;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  background: linear-gradient(
    to right,
    rgba(255, 153, 8, 1),
    rgba(237, 103, 31, 1)
  );
  cursor: pointer;
}
.tableContainer {
  margin-top: 25px;
}

.assetContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 20px; */
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  border-radius: 12px;
  margin-bottom: 40px;
}

.assetCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assetCon h3 {
  color: gray;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
}
.assetCon p {
  font-size: 14px;
  color: black;
  font-weight: 500;
}
