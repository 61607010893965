.tabContainer {
  margin-top: 15px;
}

/* .AddButton{
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.5);
} */
.tableContainer {
  margin-top: 5px;
}
.imgContainer {
  width: 100px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  & img {
    width: 100%;
    height: 100%;
  }
}

.statusbox {
  border-radius: 5px;
  /* padding: -5% 0; */
  display: flex;
  justify-content: space-around;
  align-items: center;

  & img {
    width: 18px;
  }

  & p {
    margin: 5px 0;
  }
}

.activeStatus {
  color: #3c9308;
  background-color: #d3fbab;
}

.inActiveStatus {
  color: #bc4607;
  background-color: #fcb9b0;
}
/* commons */

/* .gradientColor {
    background: linear-gradient(90deg, #ff9908 0%, #ed671f 100.02%);
  } */

/*    drawer css */
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #e95e33;
}

.chooseimgConatiner {
  position: relative;
  width: 100%;
  height: 125px;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  & .displayimg {
    width: 20%;
  }

  & .cancelbtn {
    position: absolute;
    top: 10px;
    left: -5px;
  }
}

.imguplodeText {
  color: #e95e33;
  font-size: 18px;
  font-weight: 600;
}

.inputContainer {
  margin: 20px 0;
}

.rediText {
  text-align: left;

  & p {
    margin: 0;
  }
}

.rediSubtext {
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.errorfieldtext {
  color: #d32f2f;
  font-size: 12px;
}
.detailCon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.detailCon h1 {
  width: 40%;
  font-size: 22px;
  font-weight: 500;
}

.detailCon h1 span {
  color: red;
}
.detailCon p {
  width: 50%;
  padding-left: 40px;
  box-sizing: border-box;
}

.detailCon div {
  width: 50%;
  /* padding-left: 40px; */
  box-sizing: border-box;
}
.paginationCon {
  margin-top: 15px;
}

.totalCountText {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0px;
}
