.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  padding: 0px 41px;
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}

.detailCard {
  width: 100%;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid grey;
}
.firstCon {
  width: 50%;
  border-right: 2px solid gray;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
  gap: 30px;
}

.indexText {
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.titleText {
  font-size: 20px;
  font-weight: 500;
  color: black;

  /* width: 40%; */
}

.secondCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.viewCon {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 20px; */
  align-items: center;
  padding: 0px 40px;
  box-sizing: border-box;
}
.viewTypeText {
  /* background-color: lightgreen;
  color: green; */
  font-size: 19px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 200px;
}

.viewStr {
  color: white;
  font-size: 20px;
  font-weight: 800;
  background-color: black;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
