.react-calendar {
    width: 100%;
    height: 100%;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    /* display: none; */
    visibility: hidden;
  }
  
  .react-calendar__navigation__label {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .react-calendar__navigation__label__divider {
    display: none;
  }
  
  .react-calendar__navigation__label__labelText {
    font-size: 22px;
  }
  
  .react-calendar__navigation__arrow {
    font-size: 30px;
  }
  
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
    font-size: 14px;
    color: grey;
  }
  
  .react-calendar {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 14px 0px;
    border-radius: 10px;
    padding: 10px;
    background: rgba(255, 234, 207, 1);
  }
  
  .react-calendar__month-view {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    background: white;
    border-radius: 10px;
  }
  
  .react-calendar__tile {
    font-weight: 900;
  }
  
  .react-calendar__tile--now {
    background-color: #F0F8FF;
  }
  
  .react-calendar__tile--active:enabled:hover {
    background: #ed671f;
    color:white;
    /* background-color: red; */
  }
  
  .react-calendar__tile--active:enabled:focus {
    background: #ed671f;
    color:white;
    /* background-color: red; */
  }
  /* background: linear-gradient(90deg, #ff9908, #ed671f); */
  
  .react-calendar__tile--range {
    background: #ffebce;
    color: black;
    font-weight: 900;
  }
  
  .react-calendar__tile--rangeStart {
    background: #ed671f;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .react-calendar__tile--rangeEnd {
    background: #ed671f;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .react-calendar__month-view__days__day--weekend{
    color: black;
  }