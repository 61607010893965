.droneProductCard {
  min-width: 300px;
  padding: 20px;
  box-sizing: border-box;
  border: 0.5px solid lightgray;
  border-radius: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.droneProductCard img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.droneProductCard h3 {
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}

.srpText {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.mrpText {
  font-size: 18px;
  font-weight: 500;
  color: #00000080;
  text-decoration: line-through;
}
.countCon {
  height: 40px;
  border: 0.5px solid lightgray;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.orangeCon {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 24px;
  color: white;
  background-color: #ffa500;
  cursor: pointer;
}
.countVal {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  color: black;
  background-color: white;
}
.orangeHead {
  font-size: 24px;
  font-weight: 700;
  color: #e95e33;
  margin-bottom: 20px;
}
.orangeHead span {
  color: #d32f2f;
  font-size: 16px;
  font-weight: 600;
}

.addressCon {
  border: 0.5px solid rgba(0, 0, 0, 0.2);

  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.priceBreakup {
  font-size: 24px;
  color: black;
  font-weight: 600;
}
.priceDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.priceDetails h3 {
  color: #848484;
  font-size: 18px;
  font-weight: 500;
}

.priceDetails p {
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.priceDetailsCon {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px #00000080;
  border-radius: 8px;
}

.savingCon {
  margin: 20px 0px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #b9ffbc;
  border-radius: 8px;
  gap: 10px;
}
.savingCon img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
.savingCon p {
  color: #1b3b01;
  font-size: 18px;
  font-weight: 700;
}
.savingCon p span {
  color: #1b3b01;
  font-size: 14px;
  font-weight: 400;
}
.finalAmountCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.finalAmountCon h3,
.finalAmountCon p {
  color: #e95e33;
  font-weight: 600;
}
