.tabContainer {
  margin-top: 15px;
}
.profileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 10px;
  align-items: center;
}
.statusText {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}
.loginDate {
  margin-bottom: 0px;
}
.loginTime {
  margin-top: 5px;
}
.tableContainer {
  /* margin-top: 25px; */
}
.paginationContainer {
  margin-top: 25px;
}
.filterContainer {
  max-height: 40px;
  margin-top: 20px;
}

.languageCon {
  display: flex;
  align-items: center;
}

.uploadMainCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 300px;
}

.uploadCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: linear-gradient(rgba(255, 153, 8, 1), rgba(237, 103, 31, 1));
  color: white;
  font-size: 13px;
  font-weight: 500;
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.weeksListCon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}

.selectedWeek {
  background: rgba(198, 239, 205, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: rgba(3, 98, 2, 1);
  font-size: 15px;
  font-weight: 600;
  width: max-content;
  cursor: pointer;
}

.unselectedWeek {
  background: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: grey;
  font-size: 15px;
  font-weight: 600;
  /* border: 1px solid rgba(237, 103, 31, 1); */
  width: max-content;
  cursor: pointer;
  background-color: white;
}

.deleteConfirmCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.reallyText {
  font-size: 25px;
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 6px 0px;
}
.reallyText span {
  font-weight: 600;
}
.bottomCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  align-items: center;
  border-top: 2px solid black;
  margin-top: 40px;
  width: 100%;
  padding-top: 40px;
}
.yesText {
  font-size: 30px;
  font-weight: 500;
  color: #e95e33;
  border-radius: 10px;
  /* padding-right: 25%; */
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noText {
  color: #e95e33;
  font-size: 30px;
  font-weight: 500;
  border: 2px solid gray;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
