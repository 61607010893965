.statusCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
  min-width: 60px;
  font-size: 15px;
  font-weight: 500;
}
