.dashBoardCon {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
  justify-content: space-between;
}
.tabContainer {
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tableContainer {
  margin-top: 25px;
}
.paginationCon {
  margin-top: 16px;
}
.languageCon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.language {
  color: White;
  font-size: 16px;
  background-color: rgba(233, 94, 51, 1);
  font-weight: 500;
  padding: 10px;
  border-radius: 10px;
}
.instructorCon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.instructor {
  /* color: rgba(102, 18, 18, 1); */
  font-size: 16px;
  /* background-color: rgba(255, 213, 213, 1); */
  font-weight: 500;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

/* create course styles */

.titleStyles {
  font-size: 20px;
  font-weight: 600;
}

/* .batchTitleStyles{
  font-size: ;
} */

.textGrayBatch {
  color: rgba(0, 0, 0, 0.5);
}

.formContainer {
  padding-left: 10px;
  padding-right: 10px;
}

.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}

.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mediaBox {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.batchCard {
  border: 0.5px solid #2d2d2d;
  border-radius: 10px;
  position: relative;
}

.batchIdCard {
  width: fit-content;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 10px;
  background: linear-gradient(
    90deg,
    rgb(255, 153, 8) 0%,
    rgb(237, 103, 31) 100.02%
  );
}

.batchContent {
  padding: 15px 20px 10px 20px;
}

.dayCardStyle {
  border-radius: 35px;
  background-color: rgb(233, 94, 51);
  width: auto;
  padding: 3px 12px;
  font-size: 12px;
  color: white;
  text-align: center;
}

.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}

/* commons */

.gradientColor {
  background: linear-gradient(90deg, #ff9908 0%, #ed671f 100.02%);
}
