.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 200px;
  transition: margin-left 0.3s ease-in-out;
}

.shifted {
  margin-left: 60px;
}

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #333;
  color: white;
  position: fixed;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 20px;
  text-align: center;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  display: block;
}

.toggle-btn {
  position: absolute;
  top: 10px;
  left: 180px;
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: left 0.3s;
}

.collapsed {
  width: 60px;
}

.collapsed .toggle-btn {
  left: 20px;
}

.collapsed ul li a {
  display: none;
}

.collapsed ul li:before {
  content: attr(data-icon);
  font-size: 24px;
  display: block;
  text-align: center;
  line-height: 50px;
}

.sidebar ul li[data-icon="home"]::before {
  content: "\1F3E0"; /* Unicode for house */
}

.sidebar ul li[data-icon="about"]::before {
  content: "\2139"; /* Unicode for information */
}

.sidebar ul li[data-icon="services"]::before {
  content: "\1F4C3"; /* Unicode for document */
}

.sidebar ul li[data-icon="contact"]::before {
  content: "\1F4E9"; /* Unicode for envelope */
}
