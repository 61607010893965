.outerContainer{
  border-radius: 12px;
  box-shadow: 0 1px 3px #bbadad;
  /* box-sizing: border-box; */
  padding: 40px 30px;
  height: 100%;

}

.container {
    border: 1px solid #d3d3d3;
    background-color: white;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .pageTitle {
    font-size: 24px;
    font-weight: 500;
    color: #006080;
    position: absolute;
    top: -58px;
    /* left: 35px; */
  }
  
  .firstItemHeader {
    /* border-bottom: 1px solid black; */
    /* height: 20%; */
    display: flex;
    flex-direction: row;
  }
  .headComm {
    /* width: 25%; */
    flex: 1;
    padding: 15px;
    /* border: 0.1px solid black; */
  }
  
  .secondRow {
    /* height: 40%; */
    /* border-bottom: 1px solid black; */
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    flex-direction: row;
  }
  
  .billToPartyBox {
    width: 50%;
    /* border: 1px solid black; */
    padding: 12px;
  }
  
  .addStudentContainer {
    /* margin-top: 80px; */
    width: 75%;
    margin: 90px auto auto;
  }
  .flexStContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  
  .stDrwBtnFlex {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    /* margin-top: 10px; */
  }

  .stDrwBtnFlextwo{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 10px;
  }
  
  .groupBilFields {
    /* margin-top: 10px; */
  }
  
  /* third row styles */
  
  .thirdRow {
    /* height: 150px; */
    padding: 10px;
    /* border: 1px solid blue; */
    border-bottom: 1px solid #d3d3d3;
  }
  
  .selectProductBox {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    /* justify-content: space-between; */
    /* display: none; */
  }
  
  .qtyBoxFlex {
    width: 75%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  
  .commonMarginTop {
    margin-top: 25px;
  }
  
  .commonMaxWidth {
    /* max-width: 80px; */
  }
  
  .fourthRow {
    /* padding: 10px; */
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d3d3d3;
  }
  
  .totalSubBox {
    width: 50%;
  
    display: flex;
    flex-direction: row;
  }
  
  .totBox {
    width: 50%;
  
    /* padding: 2px; */
    display: flex;
    justify-content: center;
  }
  
  .txtAlignCenter {
    text-align: center;
  }
  
  .finalAmtBox {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .gstBoxMain {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .gstchild {
    flex: 1;
  }
  
  /* fifth row */
  .fifthRow {
    display: flex;
    flex-direction: row;
  }
  
  .remarksBox {
    flex: 1;
  }
  
  .amtPayableBox {
    display: flex;
    flex-direction: row;
  }
  
  .revChrgComBx {
    flex: 1;
    padding: 10px;
  }
  
  /* border-styles */
  
  .headComm:nth-child(1),
  .headComm:nth-child(2) {
    border-right: 1px solid #d3d3d3;
  }
  
  .billToPartyBox:nth-child(1) {
    border-right: 1px solid #d3d3d3;
  }
  
  .borderFull {
    border: 1px solid #d3d3d3;
  }
  .borderBottom {
    border-bottom: 1px solid #d3d3d3;
  }
  
  .borderTop {
    border-top: 1px solid #d3d3d3;
  }
  
  .borderRight {
    border-right: 1px solid #d3d3d3;
  }
  
  .comProDuctBox {
    flex: 1;
  }
  
  .marginTop15 {
    margin-top: 15px;
  }
  
  .padding10 {
    padding: 10px;
  }
  
  .billPartyFlexCeter {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .saveandnextbtn {
    background: linear-gradient(270deg, #ef6b1e 7.35%, #fe960a 100%);
    color: white;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 550;
    margin-top: 25px;
  }
  
  .loadingStyle {
    background: gray;
    color: white;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 550;
    margin-top: 25px;
  }