.addLocations{
 font-size: 20px;
 font-weight: 600;  
 color: rgb(233, 94, 51); 
}


.containerW{
    margin-top: 20px;
    padding: 0px 10px
}

.tabContainer{
    margin-top: 15px;
}
.profileCon{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    gap: 10px;
    align-items: center;
}
.statusText{
    padding: 10px 15px;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
}
.loginDate {
    margin-bottom: 0px;
}
.loginTime{
    margin-top: 5px;

}
.tableContainer{
    /* margin-top: 25px; */
}
.paginationContainer{
    margin-top: 25px;
}
.filterContainer {
    max-height: 40px;
    margin-top: 20px;

}

.languageCon{
    display: flex;
    justify-content: center;
    
}