.tabContainer {
  margin-top: 15px;
}
.profileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  column-gap: 15px;
  align-items: center;
}
.statusText {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}
.loginDate {
  margin-bottom: 0px;
}
.loginTime {
  margin-top: 5px;
}
.tableContainer {
  /* margin-top: 25px; */
}
.paginationContainer {
  margin-top: 25px;
}
.filterContainer {
  max-height: 40px;
  margin-top: 20px;
}

/* user profile styles imported agrm*/

.container {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.topcards {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 40px;
  box-sizing: border-box;
}
.leftCon {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 20%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  height: max-content;
}
.rightCon {
  width: 76%;
}

.inputContainer {
  margin: 10px 0;
}

.modalContainer {
  padding: 20px;
}

.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #e95e33;
}

.chooseimgConatiner {
  position: relative;
  width: 100%;
  height: 125px;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  & .displayimg {
    width: 20%;
  }

  & .cancelbtn {
    position: absolute;
    top: 10px;
    left: -5px;
  }
}

.activeTab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: black;
  font-weight: 600;
  background-color: rgba(255, 234, 207, 1);
  cursor: pointer;
}
.normalTab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: black;
  font-weight: 500;
  background-color: none;
  cursor: pointer;
}

.userProfileIconCon {
  position: relative;
}
.editIcon {
  position: absolute;
  right: -20px;
  bottom: 10px;
  cursor: pointer;
}

.statusCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
  min-width: 60px;
  font-size: 15px;
  font-weight: 500;
}
