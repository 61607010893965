.tableContainer {
  margin-top: 15px;
}
.paginationCon {
  margin-top: 15px;
}

.statusText {
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  min-width: 120px;
  max-width: 150px;
  margin: 0px auto;
}

.totalCount {
  margin: 10px 0px 0px 0px;
  font-size: 20px;
  font-weight: 500;
}

.deleteConfirmCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.reallyText {
  font-size: 25px;
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 6px 0px;
}
.bottomCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  align-items: center;
  /* border-top: 2px solid black; */
  margin-top: 40px;
  width: 100%;
}
.yesText {
  font-size: 30px;
  font-weight: 500;
  color: gray;
  /* border-right: 2px solid black; */
  border: 2px solid #e95e33;
  border-radius: 10px;
  /* padding-right: 25%; */
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .formContainer {
  padding: 0px 40px;
} */
.noText {
  color: #e95e33;
  font-size: 30px;
  font-weight: 500;
  border: 2px solid gray;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
