@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.drawerLeftFontStyles {
  color: #00000080;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.timeCardPads {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
}

.breadElement {
  text-decoration: none;
}

/* .breadElement :hover {
  text-decoration: underline;
} */

/* font styles */

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}

.bold-400 {
  font-weight: 400;
}

.bold-600 {
  font-weight: 600;
}
/* margins */

.m-0 {
  margin: 0px;
}

/* margins for p tags */
.pm0 {
  margin: 0px;
}
.pmt0 {
  margin-top: 0px;
}
.pmb0 {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* colors */
.orange-1 {
  /* #E95E33 */
  color: #e95e33;
}

.btnLinearGradient {
  background: linear-gradient(
    90deg,
    rgb(255, 153, 8) 0%,
    rgb(237, 103, 31) 100.02%
  );
}

.gray-1 {
  color: #00000080;
}

.grayTitleFont {
  color: #848484;
}

#combo-box-container {
  float: right;
  display: block;
  margin: 8px 0 32px 8px;
}

#helper-text {
  vertical-align: bottom;
}

.actionsCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.totalCountText {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0px;
}

.alertBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.alertBox h3 {
  color: #fe9a0a;
  font-size: 24px;
  font-weight: 700;
}

.alertBtnCon {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}
