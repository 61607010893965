.mainContainer {
  width: 100%;
  border-radius: 10px;
  /* border: 0.3px solid rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.topHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressHead {
  font-size: 35px;
  font-weight: 500;
}

.addAddress {
  font-size: 23px;
  font-weight: 500;
  color: #ff9908;
  text-decoration: underline;
  text-decoration-color: #ff9908;
  cursor: pointer;
}
.miniContainer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.addressType {
  background-color: #ffeacf;
  color: #e95e33;
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  max-width: 100px;
}

.addressCon {
  width: 100%;
  display: flex;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between;
}

.addressHead {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0px;
}

.addressLoc {
  font-size: 22px;
  font-weight: 400;
}
.actionCon {
  display: flex;
  justify-content: space-between;
}
.actionImg {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.addressMap img{
  width: 150px;
  height: 150px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    width: 100%;
    border-radius: 10px;
    /* border: 0.3px solid rgba(0, 0, 0, 0.5); */
    box-shadow: none;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .addressHead {
    font-size: 23px;
    font-weight: 600;
    margin: 0px;
  }
  .addAddress {
    font-size: 20px;
    font-weight: 600;
    color: #ff9908;
    text-decoration: underline;
    text-decoration-color: #ff9908;
    cursor: pointer;
  }
  .addressCon {
    width: 100%;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
    padding: 20px;
    /* justify-content: space-between; */
  }
  .textContainer {
    width: 70%;
  }
  .textContainer p {
    word-wrap: wrap;
  }
  .addressLoc {
    width: 100%;
    word-wrap: break-word;
    font-size: 22px;
    font-weight: 400;
  }

  .actionCon {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .actionImg {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
}
