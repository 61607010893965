.mainWrapperCon {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.requestform {
  font-weight: 500;
}

.colorwhite {
  color: rgba(255, 255, 255, 1);
}

.marginzero {
  margin: 0px;
}

.fontsizetwentyEight {
  font-size: 28px;
  font-weight: 550;
  margin-bottom: 12px;
  margin-top: 70px;
}

.drone {
  position: absolute;
  top: -2%;
  right: 6%;
  /* width: 100%; */
}

.line {
  position: absolute;
  top: 13%;
  left: 16%;
  width: 80%;
}

.lineimg {
  width: 100%;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 90%;
  z-index: 500;
}

.widthContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -400px;
  z-index: 600;
}

.displayFlex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.fontsizetwentyfour {
  font-size: 24px;
}

.numberCon {
  display: flex;
  align-items: flex-start;
}

.numberone {
  color: #f06e1e;
  font-size: 36px;
  font-weight: 500;
}

.textdetails {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 5px;
}

.contentLeft {
  box-sizing: border-box;
  padding: 20px;
  width: 60%;

  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.contentRight {
  box-sizing: border-box;
  padding: 20px;
  width: 37%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.marginTop {
  margin-top: 28px;
}

.textAlignEnd {
  text-align: end;
}

.colorprimary {
  color: #f06e1e;
}

.textDecoration {
  text-decoration: underline;
}

.cursorp {
  cursor: pointer;
}

.marginBottomzero {
  margin-bottom: 0px;
}

.addressWrapper {
  margin-top: 30px;
}

.mainWrapperConaddress {
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-sizing: border-box;
  padding: 0px 0px 25px 10px;
  margin-bottom: 28px;
  position: relative;
}

.leftWrapper {
  width: 70%;
  margin-top: -19px;
}

.rightWrapper {
  width: 25%;
  position: relative;
}

.selectedaddress {
  background: linear-gradient(270deg, #ef6b1e 7.35%, #fe960a 100%);
  color: white;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 99%);
  /* background-color: red; */
  height: max-content;
}

.backgroundrect {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  z-index: 500;
}

.selectaddresstext {
  text-align: end;
  position: absolute;
  z-index: 600;
  z-index: 600;
  top: 4px;
  right: 5%;
  color: white;
}

.rectContainer {
  width: 100%;
}

.mainrectImg {
  text-align: end;
}

.rectImg {
}

.selectedactive {
  background-color: rgba(255, 234, 207, 1);
}

.activeaddress {
}

.editanddelete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 22px;
  padding: 10px 10px 0px 0px;
}

.icons {
  border-radius: 50%;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-sizing: border-box;
  padding: 7px 11px;
}

.usernamedetails {
  font-weight: 600;
}

.saveandnextbtn {
  background: linear-gradient(270deg, #ef6b1e 7.35%, #fe960a 100%);
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 550;
  margin-top: 25px;
  cursor: pointer;
}

.fontWeightfiveh {
  font-weight: 500;
}

.lightgraycolor {
  color: rgba(0, 0, 0, 0.8);
}

.fontsizesixteen {
  font-size: 16px;
}

.fruitsCon {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 170px;
  gap: 10px;
  box-sizing: border-box;
  /* padding: 10px; */
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(233, 94, 51, 0.15);
}

.displayFlexfruit {
  display: flex;
  gap: 13px;
  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  padding: 10px;
  padding-bottom: 34px;
}

.displayFlexfruit::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
}

.displayFlexfruit::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.displayFlexfruit::-webkit-scrollbar-track {
  background-color: transparent;
}

.selectedFruit {
  background: var(
    --Primary-gradient,
    linear-gradient(90deg, #ff9908 0%, #ed671f 100.02%)
  );
}

.fruitrealimg {
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.mainfruitlogo {
  width: 100%;
}

.cropStageContainer {
  display: flex;
  gap: 22px;
}

.cropstageCon {
  width: 31px;
  margin: 0 auto;
}
.cropicon {
  width: 100%;
}

.cropStage {
  width: 20%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 25px 5px 5px 5px;
  border-radius: 5px;
}

.activeStage {
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(233, 94, 51, 0.25);
}

.marginfour {
  margin: 4px;
}

.selectedFruittext {
  color: white;
}

.margintopfifty {
  margin-top: 50px;
}

.normalClass {
  border: 1px solid gray;
  cursor: pointer;
}

.active {
  box-sizing: border-box;
  border-radius: 3.569px;
  border: 2px solid #c4c4c4;
  background: rgba(240, 240, 240, 0.3);
  margin-bottom: 15px;
  padding: 16px;
  width: 50%;
}

.titleContainer {
  box-sizing: border-box;
  border-radius: 3.569px;
  border: 0.5px solid #c4c4c4;
  background: rgba(240, 240, 240, 0.3);
  margin-bottom: 15px;
  padding: 16px;
  width: 50%;
}

.textStack {
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 17px;
}

.textStackhorizontal {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.moreInfotitle {
  color: rgba(0, 0, 0, 0.8);
}

.questiontitle {
  font-weight: 550;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .textStack {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
}
