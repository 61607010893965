.modal_show {
  display: block;
}

.modal_hide {
  display: none;
}

.eventIDDate{
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: space-between; */

  & h5ya{
    margin: 5px 0;
    font-size: 14px;
    font-weight: 500;
  }

  & p {
    margin: 5px 0;
    color: #ADADAD;
    font-size: 14px;
    font-weight: 500;
  }
}

.eventTitle{
  margin: 5px 0;
}

.eventPilotname{
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  & p{
    margin: 5px 0;
  }
}
.imgnameContainer{
  display: flex;
  gap: 10px;
  align-items: center;

  & img{
    border-radius: 25px;
    border: none;
  }

  & h4{
    margin: 5px 0;
  }
}

.eventSIProvider{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;

  & img{
    border-radius: 25px;
    border: none;
  }
}