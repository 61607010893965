.mainContainer {
  border-radius: 10px;
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topContainer button {
  color: white;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  background: linear-gradient(
    to right,
    rgba(255, 153, 8, 1),
    rgba(237, 103, 31, 1)
  );
  cursor: pointer;
}
.tableContainer {
  margin-top: 25px;
}

.statusText {
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  min-width: 120px;
  max-width: 150px;
  margin: 0px auto;
}


.paginationContainer {
  margin-top: 25px;
}