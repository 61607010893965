.rowListing{
  display: flex;
  align-items: center;
  padding:5px 5px 5px 0px;
}

.iconClass{
  margin-right: 5px;
}

.containerClass{
  position: absolute;
  right:-105px;
  top:30%;
  background:#ffffffB4;
  z-index: 99999;
  transition: right 0.3s ease-in-out;
}

.containerClass:hover{
  right:0px;
}

.titleClass{
  width: 100px;
  word-break: break-all;
}