body {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  background: #fafafa;
}

.rct-item-content {
  border: dotted 1px orange;
  width: max-content;
}

.rct-item {
  background: orange !important;
  border-color: orange !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background-color: #ffffff;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: none;
}

.react-calendar-timeline .rct-header .rct-label {
  border-bottom: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-color: #ccc;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #eeeeee87;
}

.react-calendar-timeline .rct-header .rct-label-group {
  font-family: "Work Sans", sans-serif;
  background: #eee;
  color: #000;
  font-size: 1rem;
}

.react-calendar-timeline .rct-sidebar-header {
  background: #eee;
  color: #fefefe !important;
  text-align: center;
  padding-top: 1rem;
  border: none;
  border-right: solid 2px #ccc !important;
}

.customMainHeader {
  /* background-color: gray; */
  background: linear-gradient(
    90deg,
    rgb(255, 153, 8) 0%,
    rgb(237, 103, 31) 100.02%
  );
}

.customSideHeader {
  /* background-color: gray; */
  background: linear-gradient(
    90deg,
    rgb(255, 153, 8) 0%,
    rgb(237, 103, 31) 100.02%
  );
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
