.breadCrumbsCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.tableContainer {
  /* margin-top: 25px; */
}
.paginationCon {
  margin-top: 15px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  gap: 20px;
}

.compoHead {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.compoHead span {
  color: red;
}

.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  /* padding: 0px 40px; */
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.inputCon {
  margin: 20px 0px;
}
