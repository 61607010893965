.paginationContainer {
  margin-top: 25px;
}
.mainContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid rgba(233, 94, 51, 1);
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .topContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background-color: rgba(251, 251, 251, 1);
    box-sizing: border-box;
    padding: 15px;
  }
  .bottomContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
  
    /* margin-left : 20px; */
  }
  .title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .bookingCon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bookText {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
  .bookId {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
    width: 25%;
  }
  .bookSpan {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    width: 30%;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 8px;
  }
  .viewandmanagebtn {
    background: linear-gradient(270deg, #ef6b1e 7.35%, #fe960a 100%);
    color: white;
    border: none;
    outline: none;
    padding: 12px;
    font-size: 18px;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
  }
  .amountCon {
    border: 1px solid rgba(233, 94, 51, 1);
    padding: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  .amountVal {
    color: rgba(233, 94, 51, 1);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .amountName {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .miniCon {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 10px;
  }
  
  .droneImg {
    margin-left: -60px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
    padding: 8px;
  }
  
  .mobileMainCon {
    display: none;
  }
  