.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.counterServiceCon {
  /* position: absolute;
  bottom: 0px; */
  /* position: relative; */
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: black;
  box-sizing: border-box;
  z-index: 462374639;
}
.privacyText {
  color: #e95e33;
  /* font-size: 24px; */
}
.droneimgCon {
  position: absolute;
}
.bottomCon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  /* margin-top: 20px; */
}
.bottomCon h3 {
  font-weight: 400;
  font-size: 24px;
}
.circleC {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  border-radius: 50%;
}
