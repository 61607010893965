.courseCard {
  display: flex;
  justify-content: space-between;

  padding: 18px;
  background: #ffffff;
  /* primary colour 2 */

  border: 0.3px solid #143f6b;
  border-radius: 10px;
  cursor: pointer;
}

.topSection {
  display: flex;
  gap: 20px;
}
