.statusC {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusCI {
  text-transform: capitalize;
  border: 1px solid grey;
  border-radius: 10px;
  padding:2px;
}
