.mainContainer {
    width: 100%;
    border-radius: 15px;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 0px #00000026;
    padding: 10px;
    align-items: center;
    /* position: relative; */
    /* height: auto; */
    /* cursor: pointer; */
  }
  .leftProfileimgContainer {
    width: 20%;
    position: relative;
    text-align: center;
    & .profilepic {
      width: 210px;
      height: 210px;
  
      border-radius: 50%;
    }
  }
  
  .editprofilepic {
    position: absolute;
    top: 10%;
    cursor: pointer;
    right: 15%;
  }
  
  .rightProfiledetaile {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
  }
  .topbar {
    display: flex;
    justify-content: space-between;
  }
  
  .cardheading {
    margin-bottom: 20px;
    & h3 {
      margin: 5px 0;
    }
  
    & p {
      margin: 5px 0;
      color: #00000080;
    }
  }
  
  .besicDetailsWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .detailsBox {
    display: flex;
  
    /* align-items: center; */
    gap: 20px;
    width: 30%;
    margin: 10px 0;
  }
  
  .detail {
    margin: 0 0 5px;
    text-transform: capitalize;
  }
  .detailemail {
    margin: 0 0 5px;
  }
  .Vstatus {
    background-color: #d4ecc3;
    color: #098d06;
    padding: 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 400;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
  }
  
  .NVstatus {
    background-color: #ff916f80;
    color: #ae2f07;
    padding: 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 400;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
  }
  
  .detailsHeading {
    margin: 5px 0;
    color: #00000080;
  }
  
  .detailimgContainer {
    width: 10%;
  
    & img {
      width: 100%;
    }
  }
  .status {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 25px;
    border-radius: 5px;
    margin: 0 0 5px;
  }
  
  .active {
    background-color: #d3fbab;
    color: #3c9308;
  }
  
  .inActive {
    background-color: #fcb9b0;
    color: #bc4607;
  }
  