.marginTopBox {
  margin-top: 10px;
}

.newServiceButton {
  color: #00000080;
  font-size: 14px;
  line-height: 16.8px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 10px;
}

/* service order drawer styles */

.landDtlsBox {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.valuesContent {
  color: #000;
  display: flex;
  max-width: 150px;
}

.keyValueBox {
  display: flex;
}

/* add service style */

.formContainer {
  /* display: flex;
  flex-direction: row;
  column-gap: 15px;
  flex-wrap: wrap; */
  /* border: 1px solid red; */
  
  padding-left: 10px;
  padding-right: 10px;
}

.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}

.fieldGridItem {
  padding: 10px;
}

.equalInputBox {
  flex: 1;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  padding: 1px;
  row-gap: 15px;
}

.serviceHistoryBox {
  flex: 0.5;
  /* border: 1px solid rgb(243, 5, 5); */
  padding: 3px;
}

.serviceStatusBox {
  /* max-width: 65%; */
  display: flex;
  align-items: center;
  width: 100%;
}

.servicewrap {
  /* border: 1px solid; */
}

.singleFieldBox {
  border: 1px solid blue;
  display: flex;
}

/* cards container */

.timeCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
