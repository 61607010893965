.tabContainer {
    margin-top: 15px;
  }
  .profileCon {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    gap: 10px;
    align-items: center;
  }
  .statusText {
    padding: 10px 15px;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
  }
  .loginDate {
    margin-bottom: 0px;
  }
  .loginTime {
    margin-top: 5px;
  }
  .tableContainer {
    margin-top: 25px;
  }
  .paginationContainer {
    margin-top: 25px;
  }
  .filterContainer {
    max-height: 40px;
    margin-top: 20px;
  }
  .filterContainer {
    max-height: 40px;
    margin-top: 20px;
  }
  .flexBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }