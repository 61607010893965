.tableContainer {
  margin-top: 10px;
}

.totalCount {
  margin: 10px 0px 0px 0px;
  font-size: 20px;
  font-weight: 500;
}
.paginationCon {
  margin-top: 16px;
}
.deleteConfirmCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.reallyText {
  font-size: 25px;
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 6px 0px;
}
.bottomCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  align-items: center;
  /* border-top: 2px solid black; */
  margin-top: 40px;
  width: 100%;
}
.yesText {
  font-size: 30px;
  font-weight: 500;
  color: gray;
  /* border-right: 2px solid black; */
  border: 2px solid #e95e33;
  border-radius: 10px;
  /* padding-right: 25%; */
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .formContainer {
  padding: 0px 40px;
} */
.noText {
  color: #e95e33;
  font-size: 30px;
  font-weight: 500;
  border: 2px solid gray;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statusText {
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  min-width: 120px;
  max-width: 150px;
  margin: 0px auto;
}

.pilotDash {
  display: flex;
}

.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  /* padding: 0px 40px; */
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}

.switchCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(196, 196, 196, 1);
  /* padding: 10px 20px; */
  border: 0.5px solid black;
  border-radius: 8px;
  box-sizing: border-box;
}

.dashboardCard {
  width: 24%;
  align-self: stretch;
  min-height: 140px;
}
