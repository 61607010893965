.tableContainer {
  /* margin-top: 25px; */
}
.paginationCon {
  margin-top: 15px;
}
.userProfileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  text-align: center;
}
.ratingsCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
