.dashboard-container .react-datepicker {
    border: 1px solid red !important;
  }
  
  .dashboard-container .react-datepicker__header {
    border: 1px solid red !important;
  }
  
  .dashboard-container .react-datepicker-wrapper input {
    border: 1px solid red !important;
  }
  