
.mainContainer {
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.25); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px;
  box-sizing: border-box;
  /* align-items: center; */
}
 
.leftContainer {
  width: 60%;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  box-sizing: border-box;
  padding: 10px;
}
 
.leftDetailsCard {
  margin-bottom: 60px;
}
.mainHeading {
  color: black;
  font-size: 22px;
  font-weight: 600;
}
.greyText {
  color: gray;
  font-size: 20px;
  font-weight: 500;
}
 
.dragDropCon {
  width: 100%;
  border: 2px dotted gray;
  height: 300px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}
 
.dropText {
  color: black;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
 
.maxText {
  color: gray;
  font-size: 18px;
  font-weight: 500;
}
 
.chooseCon {
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
 
.browseFilesText {
  margin-left: auto;
  border-radius: 10px;
  border: 1px solid #ed671f;
  color: #ff9908;
  font-size: 20px;
  background: none;
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px 20px;
  cursor: pointer;
}
 
.topContainer {
  display: none;
}
 
.rightContainer {
  width: 30%;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
  height: 100%;
}
 
.documentTitle {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 50px;
}
 
.documentList {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0px;
}
.documentCard {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 20px;
  width: 100%;
  /* align-items: center; */
}
.documentName {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 30px;
  overflow-wrap: break-word;
  width: 80%;
  /* text-wrap: wrap; */


  /* overflow-wrap: anywhere; */
}
.submitBtn {
  display: inline-block;
  padding: 15px 44px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  background: linear-gradient(90deg, #ff9908, #ed671f);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}
 
.pendingBtn {
  display: inline-block;
  padding: 15px 44px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  background: orange;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}
.uploadedBtn {
  display: inline-block;
  padding: 15px 44px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  background: #3C9308;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;

}
.loadingStyles {
  display: inline-block;
  padding: 15px 44px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  background: gray;
  border-radius: 5px;
  border: none;
  /* cursor: pointer; */
  width: 100%;
  margin-bottom: 20px;

}
 
.selectedImage {
  width: 200px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
 
  /* height: 300px; */
}
 
.crossIcon {
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: white;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
 
.downloadIcon {
  position: absolute;
  bottom: 5%;
  right: 5%;
  background-color: white;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
 

.Vstatus{
  background-color: #D4ECC3;
  color: #098D06;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 400;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  margin-left: 10px;
}

.NVstatus{
  background-color: #FF916F80;
  color: #AE2F07;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 400;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  margin-left: 10px;
}