.tabContainer {
  margin-top: 15px;
}
.profileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 10px;
  align-items: center;
}
.statusText {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}
.loginDate {
  margin-bottom: 0px;
}
.loginTime {
  margin-top: 5px;
}
.tableContainer {
  /* margin-top: 25px; */
}
.paginationContainer {
  margin-top: 25px;
}
.filterContainer {
  max-height: 40px;
  margin-top: 20px;
}

.languageCon {
  display: flex;
  align-items: center;
}

.addLocations {
  font-size: 20px;
  font-weight: 600;
  color: rgb(233, 94, 51);
}

.containerW {
  margin-top: 20px;
  padding: 0px 10px;
}

.tabContainer {
  margin-top: 15px;
}
.profileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 10px;
  align-items: center;
}
.statusText {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}
.loginDate {
  margin-bottom: 0px;
}
.loginTime {
  margin-top: 5px;
}
.tableContainer {
  /* margin-top: 25px; */
}
.paginationContainer {
  margin-top: 25px;
}
.filterContainer {
  max-height: 40px;
  margin-top: 20px;
}

.languageCon {
  display: flex;
  justify-content: center;
}

.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  padding: 0px;
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}
