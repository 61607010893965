.cursorPointer {
  cursor: pointer;
}

.downloadBtn {
  color: white;
  background: linear-gradient(270deg, #fb5807, #fe9a0a);
  padding: 10px 20px;
  box-sizing: border-box;
  min-width: 150px;
  /* width: max-content; */
  white-space: nowrap;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
