.flexWrapper {
  display: flex;
}

.leftWrapper {
  width: 70%;
}

.rightWrapper {
  width: 25%;
}

.adduserbtn {
  color: white;
  border-radius: 10px;
  background: var(
    --Primary-gradient,
    linear-gradient(90deg, #ff9908 0%, #ed671f 100.02%)
  );
  border: none;
  padding: 18px;
  font-size: 18px;
  height: 100%;
}

.flexforText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priceContainer {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 10px;
  margin-top: 22px;
}

.fonttwentytwo {
  font-weight: 550;
}

.colorlightGray {
  color: rgba(132, 132, 132, 1);
}

.congratsCon {
  border-radius: 5px;
  background: #b9ffbc;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
}

.greenColor {
  color: rgba(27, 59, 1, 1);
}

.fontBold {
  font-weight: 550;
}

.marginZero {
  margin: 0px;
}

.congratsleft {
  width: 20%;
}

.congratsright {
  width: 75%;
}

.colorPrimary {
  color: rgba(233, 94, 51, 1);
}

.margintopbottom {
  margin: 7px 0px;
}

.bookcourseBtn {
  border-radius: 10px;
  background: linear-gradient(90deg, #04da00 0%, #099a06 100.02%);
  border: none;
  outline: none;
  padding: 20px;
  width: 100%;
  color: white;
  font-weight: 550;
  cursor: pointer;
}

.loadingStyle {
  border-radius: 10px;
  background: gray;
  border: none;
  outline: none;
  padding: 20px;
  width: 100%;
  color: white;
  font-weight: 550;
  /* cursor: pointer; */
}

.bookNowContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.widthContainerforDrop {
  width: 90%;
  margin: 10px 0px;
}

.dateContainer {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addressBtn {
  margin-bottom: 20px;
}

.btnContainer {
  display: flex;
  gap: 20px;
}
