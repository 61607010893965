.breadCrumbsCon {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
}

.topContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0px 20px;
  box-sizing: border-box;
  margin: 30px 0px;
}

.topContainerLeft {
  width: 70%;
  background-color: rgba(255, 241, 221, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
}

.middleContainerLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  /* margin: 30px 0px; */
  gap: 20px;
}
.dataLeft {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.detailsCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}

.bulletPointsCon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
}
.bulletPointsCon div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.bulletPointsCon div h3 {
  width: 40%;
}
.detailsCon div {
  width: 30%;
  margin-bottom: 20px;
}
.detailsCon div p {
  word-wrap: break-word;
}
.orderProductsCon {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 10px;
}
.topContainerRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  width: 28%;
  color: black;
  /* font-size: 20px; */
  font-weight: 400;
}
.paymentDetailsMainCon {
  width: 28%;
}
.paymentTitle {
  padding: 0px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.paymentDetailsCon {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.topContainerRight span {
  color: black;
  /* background-color: rgba(235, 250, 255, 1); */
  padding: 10px 20px;
  box-sizing: border-box;
  color: rgba(27, 193, 255, 1);
  font-weight: 600;
  border-radius: 10px;
}

.transactionListCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
}
.transactionCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  color: white;
  margin-bottom: 10px;
}
.transactionCon h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.transactionCon p {
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0px;
}
.transactionCon h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

.withdrawListCon {
  margin-top: 20px;
}
.withdrawCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.withdrawBlack {
  color: black;
  /* font-size: 24px; */
  font-weight: 400;
}
.withdrawGrey {
  color: gray;
  /* font-size: 24px; */
  font-weight: 400;
  margin: 0px;
}
.withdrawRed {
  background-color: rgba(255, 227, 219, 1);
  color: rgba(237, 103, 31, 1);
  font-size: 12px;
  /* font-weight: 500; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 20px;
}

.downloadBtn {
  border: none;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
