.flexStContainer{
    padding: 20px;
}


.titletag{
    font-size: 22px;
    font-weight: 500;
    color: #e95e33;
}

.stDrwBtnFlextwo{
    display: flex;
 gap: 20px
}