.addLocations {
  font-size: 20px;
  font-weight: 600;
  color: rgb(233, 94, 51);
}

.containerW {
  margin-top: 21px;
  padding: 0px 10px;
}

.tabContainer {
  margin-top: 15px;
}
.profileCon {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 10px;
  align-items: center;
}
.statusText {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}
.loginDate {
  margin-bottom: 0px;
}
.loginTime {
  margin-top: 5px;
}
.tableContainer {
  /* margin-top: 25px; */
}
.paginationContainer {
  margin-top: 25px;
}
.filterContainer {
  max-height: 40px;
  margin-top: 20px;
}

.languageCon {
  display: flex;
  justify-content: center;
}

.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  padding: 0px;
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  gap: 20px;
  box-sizing: "border-box";
  border-radius: 10px;
}

.customerCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
}
.customerCon img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
}

.serviceLocationCon {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.mapDataCon {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 0px;
}

.mapCon {
  display: flex;
  justify-content: center;
  width: 40%;
}
.createdCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 10px 0px;
  background-color: rgba(247, 247, 247, 1);
}
.createdCon div h3 {
  color: rgba(254, 154, 10, 1);
  font-size: 16px;
  font-weight: 500;
}
.createdCon div p {
  font-size: 15px;
  font-weight: 500;
}
.productsList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin: 20px 0px;
}
.productItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: rgba(248, 248, 248, 1);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.productItem p {
  font-weight: 700;
}

.wrapCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  /* align-items: center; */
  margin: 20px 0px;
  flex-wrap: wrap;
}

.itemCon {
  width: 30%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 10px;
}

.itemCon h4 {
  color: rgba(254, 154, 10, 1);
  font-size: 18px;
  font-weight: 500;
}
.itemCon p {
  color: black;
  font-size: 16px;
  font-weight: 500;
}
.signedCon {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: rgba(244, 222, 207, 1);
}
.signedCon p {
  font-weight: 600;
}

.cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  overflow: auto;
}

.cardItem {
  border-radius: 10px;
  padding: 20px 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  align-self: normal;
}
.cardItem h3 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid white;
  padding-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}

.cardItem p {
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
