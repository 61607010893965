.gridContainer {
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  height: 100%;
  width: 100%;
  gap: 30px;
}

.gridContainer::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar (for horizontal scrolling) */
}

.gridContainer::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.gridContainer::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3); /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
  border: 1px solid rgba(255, 255, 255, 0.6); /* Dashed border for the scrollbar */
}

.gridContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Darken on hover */
}

/* For Firefox */
.gridContainer {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Custom color */
}
.gridItem {
  min-width: 140px; /* or - flex: 0 50% - or - flex-basis: 50% - */
  width: max-content;
  /*demo*/
  /* flex: 50%; */
  margin-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardIcon {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gridItem p :first-child {
  font-weight: 700;
  font-size: 20px;
}
.gridItem p :nth-child(2) {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.countVAl {
  white-space: nowrap;
}
