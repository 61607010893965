.layoutBox {
  display: flex;
  flex-direction: column;
  
  gap: 10px;
  /* box-sizing: border-box; */
}

.filterBox {
  box-sizing: border-box;
  padding: 3px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.calendarBox {
  box-sizing: border-box;
  padding: 3px;
}

.romBtn {
  border: 1px solid black;
  cursor: pointer;
}

.romBtnActive {
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
  background-color: black;
  color: white;
}
