.titleStyles {
  font-size: 20px;
  font-weight: 600;
}
.formContainer {
  padding: 0px 40px;
}
.commonFieldsBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
}
.dropBoxStyles {
  width: 100%;
  border: 2px dotted #fe9a0a;
  height: 200px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imgCardBox {
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
}

.switchCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(196, 196, 196, 1);
  /* padding: 10px 20px; */
  border: 0.5px solid black;
  border-radius: 8px;
  box-sizing: border-box;
}
