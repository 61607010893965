.mainContainer {
  /* display: flex; */
  /* gap: 5%; */
  /* align-items: center; */
  height: 100%;
  overflow-x: hidden;
}

/* .leftCon {
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
} */

.leftCon {
  position: absolute;
  top: 0px;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0px 10px 10px;
  margin: 15px;
  border-radius: 10px;
  overflow: scroll;
  height: fit-content;
  max-height: 100vh;
  width: 23%;
}
.rightCon {
  /* width: 75%; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
}

.bottomContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 30px;
  bottom: 20px;
  width: 75%;
  /* left: 50%;
  right: 50%; */
  justify-content: center;
  right: 0px;
}
.topCon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
}

.pilotCon {
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  background: white;
}

.locationTitle {
  color: grey;
  /* font-size: 18px; */
  font-weight: 400;
}

.locationTitle span {
  color: rgba(237, 103, 31, 1);
  font-weight: 600;
}

.completedCon {
  background: linear-gradient(
    to right,
    rgba(60, 147, 8, 1),
    rgba(4, 180, 0, 1)
  );
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  margin-right: 20px;
}

.completedCon h1 {
  color: white;
  font-size: 21px;
  font-weight: 600;
}

.completedCon h3 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.pendingCon {
  background: linear-gradient(
    to right,
    rgba(148, 11, 11, 1),
    rgba(185, 5, 5, 1)
  );
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
}

.pendingCon h1 {
  color: white;
  font-size: 21px;
  font-weight: 600;
}

.pendingCon h3 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.activePilot {
  border: 2px solid #ff9908;
  position: sticky;
  top: 10px;
  z-index: 100;
}

.pilotsText {
  /* position: sticky;
  top: 0;
  z-index: 100;
  background-color: white; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.dividepilot {
  display: flex;
  font-size: 18px;
  font-weight: 900;
}

.inactivePilot {
}

.typeListing {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.indiCard {
  width: 18%;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: 900;
  text-transform: capitalize;
  padding: 5px 2px;
}

.indiCard1 {
  width: 23%;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: 900;
  text-transform: capitalize;
  padding: 5px 2px;
}

.beforefullscreen {
  /* Your regular styles for the content */
  height: calc(100vh - 64px);
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the background color and opacity as needed */
  /* Add other styles as needed */
}

.zoomButtons {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 999999;
  background: white;
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.statusClass {
  display: flex;
  justify-content: space-between;
}
