.container {
  /* background-color: brown; */
  /* height: 100vh; */
  /* height: auto; */

  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftSection {
  flex: 1;
}

.authBox {
  padding: 10px;
  /* border: 1px solid red; */
  width: 65%;
  margin: auto;
  margin-top: 65px;
  /* text-align: center; */
}
.mainLogin {
  font-size: 36px;
  font-weight: 600;
  line-height: 43.2px;
  text-align: center;
}

.loginTypesBox {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  /* border: 1px solid black; */
}

.enterLine {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  margin-top: 10px;
}

.rightSection {
  position: relative;
  flex: 1;
  /* height: auto; */
  min-height: 100vh;
  overflow-y: hidden;
  /* background-image: url("../Assets/loginBg2.png"); */
  background-image: url("../Assets/adminPanelLoginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  /* background-size: 100% 100%; */
  /* background-color: #e95e33; */
}

/* tabs styles */

.tabsMain {
  border: 1px solid black;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  column-gap: 20px;
}

/* responsive styles */

@media only screen and (min-width: 1200px) {
  .authBox {
    padding: 20px;
    width: 50%;
  }
}
