/* CustomTable.css */
div::-webkit-scrollbar {
   width: 10px; /* adjust as needed */
   height: 10px; 
 }
 
 div::-webkit-scrollbar-thumb {
   background-color: rgba(217, 217, 217, 1);
   border-radius: 5px; 
   /* height: 5px; */
 }
 
 div::-webkit-scrollbar-track {
   background-color: white; /* color of the track */
 }
 