.cardMainContainer {
    /* display: flex; */
    /* flex-flow: row wrap; */
    /* width: 100%; */
    /* margin-left: 50px;
      margin-right: 50px; */
    /* justify-content: center; */
    /* gap: 15px; */
    width: 50%;
    border: 1px solid red;
  }
  
  .cardstyles {
    /* width: 100%; */
    background: #ffffff;
    /* border: 1px solid blueviolet; */
  
    /* margin-top: 20px; */
    /* margin-right: 20px; */
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
  
    box-shadow: 0px 4px 10px 0px #00000026;
  }
  .imgresp {
    width: 100px;
    height: 100px;
  }
  
  .fontStyle {
    font-size: 22px;
    margin: 0px;
    margin-left: 15px;
    font-weight: 500;
  }
  
  .progressBar {
    /* border: ; */
    width: 100%;
    /* position: absolute; */
    bottom: 0px;
    /* background: linear-gradient(90deg, #006080 0%, #00aae2 100%); */
    /* height: 20px; */
    color: white;
    /* margin-left: -20px; */
    border-bottom-left-radius: 5px;
  }
  
  .barstyles {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .mainBranchBox {
    background: linear-gradient(
      165.95deg,
      #28b7e7 -87.7%,
      #159adb 4.06%,
      #a18dce 101.01%
    );
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    position: absolute;
    right: -10px;
    top: -10px;
  }
  
  .mailBoxStyles {
    background-color: #ffc7ce;
    border-radius: 5px;
    position: relative;
    bottom: 2px;
    padding: 4px 4px;
    color: #9c0006;
    font-size: 12px;
    /* height: px; */
  }
  .mailBoxStylesStudent {
    background-color: #ffeb9c;
    border-radius: 5px;
    position: relative;
    bottom: 2px;
    padding: 4px 4px;
    font-size: 12px;
    color: #9c6500;
  }
  
  .verification {
    display: flex;
    color: #57c600;
  }
  
  .contactResponsive {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 1400px) {
    .fontStyle {
      width: 80%;
    }
  }
  
  @media screen and (max-width: 985px) {
    .fontStyle {
      font-size: 18px;
    }
  
    .imgresp {
      width: 65px;
    }
  }
  
  @media screen and (max-width: 975px) {
    .contactResponsive {
      flex-direction: column !important;
    }
  }
  
  /* institutecard updated styles */
  
  .newContainer {
    /* border: 1px solid red; */
    /* padding: 20px 20px 0px 20px; */
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 0px #00000026;
    position: relative;
    height: auto;
    cursor: pointer;
  }


  
  .firstSec {
    /* border: 1px solid salmon; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .newInstituteTitle {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .secondSec {
    /* border: 1px solid seagreen; */
    display: flex;
  }
  
  .thirdSec {
    /* border: 1px solid gold; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .newmailBox {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .newphoneBox {
    /* border: 1px solid brown; */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .newTitleColor {
    font-size: 23px;
    color: #006080;
  }
  
  /* @media screen and (max-width: 910px) {
    .thirdSec {
      flex-direction: column ;
    }
  } */
  
  /* global */
  
  .commonFlex {
    display: flex;
  }
  
  .comMarginTen {
    margin-left: 10px;
  }
  